// action types
export const spRenew = "Supplier Portal Renew";
export const spUserClick = "Supplier Portal User Click";
export const spUserLogin = "Supplier Portal User Log-in";
export const spContactAdded = "Supplier Portal Contact Added";
export const spSubcontractorAdded = "Supplier Portal Subcontractor Added";
export const spHelpTourStarted = "Supplier Portal Help Tour Started";
export const spHelpTourFinished = "Supplier Portal Help Tour Finished";
export const spSubmission = "Supplier Portal Submission";
export const spEvidenceUploadFailure =
  "Supplier Portal Evidence Upload Failure";
export const spResetPassword = "Supplier Portal Reset Password";
export const spSwitchSupplier = "Supplier Portal Switch Supplier";
export const contactUsClick = "Contact Us Click";
export const beelinePortalClick = "Beeline Portal Click";
export const coupaSupplierPortalClick = "Coupa Supplier Portal Click";
export const spSubcontractorAction = "Supplier Portal Subcontractor Action";
export const spSubcontractorActionError =
  "Supplier Portal Subcontractor Action Error";
export const spContactAction = "Supplier Portal Contact Action";

// sub action types
export const added = "added";
export const modified = "modified";
export const deleted = "deleted";
export const promoted = "promoted";
export const renewed = "renewed";
export const deactivated = "deactivated";

// click interaction targets
export const requestManualSubmission = "Request Manual Submission";
export const submitMaEvidence = "Submit MA Evidence";
export const submitSAEvidence = "Submit SA Evidence";
export const beelinePortal = "Beeline Portal";
export const coupaSupplierPortal = "Coupa Supplier Portal";
export const addContact = "Add Contact";
export const learnMore = "Learn More";
export const helpTour = "Help Tour";
export const addSubcontractor = "Add Subcontractor";

// submission types
export const monitoringAction = "Monitoring Action";
export const monitoringActionV2 = "Monitoring Action";
export const iatpmEvidence = "IATPM Evidence";
export const iatpmRemediationPlan = "IATPM Remediation Plan";
export const manualSubmission = "Manual Submission";
export const marSubmission = "MAR Submission";

// error types
export const spSubmissionError = "Supplier Portal Submission Error";
export const spUploadError = "Supplier Portal Upload Error";
export const spAddContactError = "Supplier Portal Add Contact Error";
export const spAddSubcontractorError =
  "Supplier Portal Add Subcontractor Error";
export const spLoginError = "Supplier Portal Log-In Error";
export const spResetPasswordError = "Supplier Portal Reset Password Error";

// error descriptions
export const spTPMCError = "TPMC Error";

// upload types
export const monitoringActionUpload = "Monitoring Action Upload";
export const iatpmUpload = "IATPM Upload";

// renew types
export const materialSubcontractor = "Material Subcontractor";
