import { Flex, Spinner, TextBody } from "@cof/omni-react";
import { SpinnerSizeType } from "@cof/omni-styles";
import { useTranslation } from "react-i18next";

interface LoadingDataV2Props {
  i18nBodyKey?: string;
  height?: string;
  spinnerSize?: SpinnerSizeType;
}
const LoadingDataV2 = ({
  i18nBodyKey,
  height = "100vh",
  spinnerSize = "huge",
}: LoadingDataV2Props) => {
  const { t } = useTranslation();
  return (
    <Flex
      alignSelf="center"
      alignItems="center"
      justifyContent="center"
      direction="column"
      height={height}
    >
      <Spinner variant="default" theme="light" size={spinnerSize} />
      {i18nBodyKey ? (
        <TextBody color="primary" theme="dark" variant="large">
          {t(i18nBodyKey)}
        </TextBody>
      ) : null}
    </Flex>
  );
};

export default LoadingDataV2;
