import React from "react";
import { useTranslation } from "react-i18next";

import { UiCloseLined } from "@cof/omni-gravity-icons-react";
import {
  Dialog,
  DialogHeader,
  DialogHeaderActions,
  TextHeading,
  Button,
  Icon,
  DialogContent,
  TextBody,
  DialogFooter,
  DialogFooterActions,
} from "@cof/omni-react";

export type ForcedLogoutDialogProps = {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

const ForcedLogoutDialog = ({
  showModal,
  setShowModal,
}: ForcedLogoutDialogProps) => {
  const { t } = useTranslation();
  return (
    <Dialog open={showModal}>
      <DialogHeader>
        <DialogHeaderActions
          leadingActions={
            <TextHeading as="h2" variant="medium" fontWeight="semibold">
              {t("forcibleLogoutDialog.title")}
            </TextHeading>
          }
          trailingActions={
            <Button
              shape="circle"
              variant="neutral"
              rank="tertiary"
              onClick={() => setShowModal(false)}
            >
              <Icon svg={UiCloseLined} />
            </Button>
          }
        ></DialogHeaderActions>
      </DialogHeader>
      <DialogContent>
        <TextBody>{t("forcibleLogoutDialog.body")}</TextBody>
      </DialogContent>
      <DialogFooter>
        <DialogFooterActions
          trailingActions={
            <Button
              variant="action"
              rank="primary"
              onClick={() => setShowModal(false)}
            >
              {t("forcibleLogoutDialog.closeBtn")}
            </Button>
          }
        ></DialogFooterActions>
      </DialogFooter>
    </Dialog>
  );
};

export default ForcedLogoutDialog;
