export function notNull<T>(x: T | null): x is T {
  return x !== null;
}

export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

/**
 * Typeguard to determine whether a value is present (not null or undefined)
 * @param t value to check for existence
 * @returns Whether t is not null or undefined
 */
export function isPresent<T>(t: T | null | undefined | void): t is T {
  return t !== null && t !== undefined;
}

/**
 * Typeguard to determine if a value is an `object`.
 * @param something any variable to check
 * @returns Whether something is an object
 */
export function isObject(
  something: unknown
): something is Record<string, unknown> {
  return (
    something !== null &&
    typeof something === "object" &&
    !Array.isArray(something)
  );
}

/**
 * Determine if a string is a key of an object, and typecast it.
 * @param obj the object to check for whether a property exists on it.
 * @param k the key/property to check whether it exists on the given object.
 * @returns whether a string is a property of an object
 */
export function isKey<T extends object | null | undefined>(
  obj: T,
  k: string
): k is string & keyof T {
  return !!obj && !!k && isObject(obj) && k in obj;
}
