/*
 * For types, see the below:
 * https://github.com/reduxjs/redux-thunk/blob/master/src/index.d.ts
 */

import { createAction } from "@reduxjs/toolkit";
import {
  removeFromLocalStorage,
  setInLocalStorage,
} from "../utils/localStorage";
import { tokenKey } from "../constants/auth";
import { SignInResponse } from "../api/GatewayClient";
import { AppDispatch } from "../setupStore";
import {
  initiateSso as apiInitiateSso,
  initiateMfa as apiInitiateMfa,
  initiateAuthKeyAuth as apiInitiateAuthKeyAuth,
} from "../api/supplierPortalApi";

export const signOut = createAction("auth/signOut");
export const forcedSignOut = createAction("auth/forcedSignOut");
export const clearForcedSignOut = createAction("auth/clearForcedSignOut");
export const updateToken = createAction<string | null>("auth/updateToken");
export const setEmail = createAction<string | undefined>("auth/setEmail");
export const signInSso = createAction<SignInResponse>("auth/signInSso");
export const loadingSso = createAction("auth/loadingSso");
export const clearLoadingSso = createAction("auth/clearLoadingSso");
export const signInMfa = createAction<SignInResponse>("auth/signInMfa");
export const signInAuthKey = createAction<SignInResponse>("auth/signInAuthKey");
export const loadingMfa = createAction("auth/loadingMfa");
export const clearLoadingMfa = createAction("auth/clearLoadingMfa");
export const setAuthKey = createAction<string | null>("auth/setAuthKey");

export const updateTokenInStorage = (
  dispatch: AppDispatch,
  token: string | null
) => {
  dispatch(updateToken(token));
  setInLocalStorage(tokenKey, token);
};

export const signOutAndClearToken = (dispatch: AppDispatch) => {
  removeFromLocalStorage(tokenKey);
  dispatch(signOut());
};

export const initiateSso = async (email: string) => {
  return await apiInitiateSso(email);
};

export const initiateMfa = async (email: string, password: string) => {
  return await apiInitiateMfa(email, password);
};

export const initiateAuthKeyAuth = async (email: string, authKey: string) => {
  return await apiInitiateAuthKeyAuth(email, authKey);
};
