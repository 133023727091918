import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "./locales/en/translation.json";
import frTranslation from "./locales/fr/translation.json";
import enErrorTrans from "./locales/en/error.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    fallbackLng: "en",
    debug: false,
    resources: {
      en: { translation: { ...enTranslation, error: { ...enErrorTrans } } },
      fr: { translation: { ...frTranslation } },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
