import { Button } from "@cof/omni-react";
import classNamesBind from "classnames/bind";

import styles from "./DismissButton.module.scss";
import { useTranslation } from "react-i18next";
import { MouseEventHandler } from "react";

const cx = classNamesBind.bind(styles);

const DismissButton = ({
  closeToast,
}: {
  closeToast: MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <Button
      className={cx("dismiss-button")}
      rank="primary"
      size="small"
      variant="neutral"
      onClick={closeToast}
    >
      {t("general.closeButton.dismiss")}
    </Button>
  );
};

export default DismissButton;
