import { createReducer } from "@reduxjs/toolkit";

import {
  clearForcedSignOut,
  forcedSignOut,
  signInSso,
  signInMfa,
  updateToken,
  loadingSso,
  clearLoadingSso,
  loadingMfa,
  clearLoadingMfa,
  setEmail,
  signInAuthKey,
} from "../actions/auth";

export interface AuthState {
  email: string | undefined;
  forcePasswordChange: boolean;
  forcedSignOut: boolean;
  loadingSso: boolean;
  loadingMfa: boolean;
  token: string | null;
}

const auth = createReducer<AuthState>(
  {
    email: undefined,
    forcePasswordChange: false,
    forcedSignOut: false,
    loadingSso: false,
    loadingMfa: false,
    token: null,
  },
  {
    [signInSso.type]: (state, action) => ({
      ...state,
      forcePasswordChange: action.payload.requiresPasswordChange,
      token: action.payload.token,
    }),
    [signInMfa.type]: (state, action) => ({
      ...state,
      forcePasswordChange: action.payload.requiresPasswordChange,
      token: action.payload.token,
    }),
    [signInAuthKey.type]: (state, action) => ({
      ...state,
      forcePasswordChange: action.payload.requiresPasswordChange,
      token: action.payload.token,
    }),
    [setEmail.type]: (state, action) => ({
      ...state,
      email: action.payload,
    }),
    [updateToken.type]: (state, action) => ({
      ...state,
      token: action.payload,
    }),
    [forcedSignOut.type]: (state) => ({ ...state, forcedSignOut: true }),
    [clearForcedSignOut.type]: (state) => ({ ...state, forcedSignOut: false }),
    [loadingSso.type]: (state) => ({ ...state, loadingSso: true }),
    [clearLoadingSso.type]: (state) => ({ ...state, loadingSso: false }),
    [loadingMfa.type]: (state) => ({ ...state, loadingMfa: true }),
    [clearLoadingMfa.type]: (state) => ({ ...state, loadingMfa: false }),
  }
);

export default auth;
