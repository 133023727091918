import useSWR, { mutate } from "swr";
import { ContactView } from "../../api/GatewayClient";
import {
  fetcher,
  GET_AVAILABLE_CONTACTS_URL,
} from "../../api/supplierPortalApi";

export const mutateAvailableContacts = (availableContacts: ContactView[]) => {
  mutate(GET_AVAILABLE_CONTACTS_URL, availableContacts, false).then();
};

const useAvailableContacts = ({
  shouldFetch,
  suspense,
}: {
  shouldFetch: boolean;
  suspense: boolean;
}) => {
  const { data, error } = useSWR<ContactView[]>(
    shouldFetch ? GET_AVAILABLE_CONTACTS_URL : null,
    fetcher,
    { suspense }
  );

  return {
    availableContacts: data ?? [],
    isLoading: !error && !data,
    error,
  };
};

export default useAvailableContacts;
