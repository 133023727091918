import * as log from "loglevel";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Key, useSWRConfig } from "swr";
import {
  signInAuthKey as actionSignInAuthKey,
  signInMfa as actionSignInMfa,
  signInSso as actionSignInSso,
  clearForcedSignOut,
  clearLoadingMfa,
  clearLoadingSso,
  forcedSignOut,
  loadingMfa,
  loadingSso,
  signOutAndClearToken,
  updateTokenInStorage,
} from "../../../actions/auth";
import { displayUpdate } from "../../../actions/userMessaging";
import {
  changePassword as apiChangePassword,
  refresh as apiRefresh,
  selectContact as apiSelectContact,
  signInMfa as apiSignInMfa,
  signInSso as apiSignInSso,
  signOut as apiSignOut,
  initiateAuthKeyAuth,
} from "../../../api/supplierPortalApi";
import AuthContext from "../../../context/auth";
import useAvailableContacts from "../../../hooks/data/useAvailableContacts";
import useFetchPermissions from "../../../hooks/data/useFetchPermissions";
import useInterval from "../../../hooks/useInterval";
import { AppDispatch, useTypedSelector } from "../../../setupStore";
import { decodeToken, getSupplierId, isExpiresSoon } from "../../../utils/jwt";
import { getAuthKey, removeAuthKeyParam } from "../../../utils/url";
import LoadingDataV2 from "../../_common/LoadingDataV2";
import ForcedLogoutDialog from "./ForcedLogoutDialog";

type AuthProviderProps = { children?: React.ReactNode };

// This component is doing too much. We should split it somehow.
const AuthProvider = ({ children }: AuthProviderProps) => {
  const [firstAttemptFinished, setFirstAttemptFinished] = useState(false);
  const [hasSignedIn, setSignedIn] = useState(false);
  const [isActive, setActive] = useState(true);

  // ===== BELOW VALUE IS A TEMPORARY HOLDING VALUE ONLY =====
  const [oldPassword, setOldPassword] = useState("");
  // ===== ABOVE VALUE IS A TEMPORARY HOLDING VALUE ONLY =====

  const dispatch = useDispatch<AppDispatch>();

  const user = useTypedSelector((state) => state.auth.token);
  const currentContact = useTypedSelector(
    (state) => state.currentContact.currentContact
  );
  const forcePasswordChange = useTypedSelector(
    (state) => state.auth.forcePasswordChange
  );
  const isForciblyLoggedOut = useTypedSelector(
    (state) => state.auth.forcedSignOut
  );

  const isSsoLoading = useTypedSelector((state) => state.auth.loadingSso);

  const { t } = useTranslation();

  const shouldFetchContacts = Boolean(user && !forcePasswordChange);
  const [hasFetchContactsFired, setHasFetchContactsFired] = useState(false);

  useEffect(() => {
    if (shouldFetchContacts) {
      setHasFetchContactsFired(true);
    }
  }, [shouldFetchContacts]);

  // only fetch the current contact if we have a user, if we're not in
  // the middle of a password change
  const {
    availableContacts,
    isLoading: isFetchingContacts,
    error: fetchingContactsError,
  } = useAvailableContacts({
    shouldFetch: shouldFetchContacts,
    suspense: false,
  });

  const hasMultipleContacts = availableContacts?.length > 1;

  const { cache } = useSWRConfig();

  // authenticated is a weird state in our application
  const isAuthenticated = () =>
    !!user && hasSignedIn && (forcePasswordChange || !currentContact);
  const isAuthorized = () =>
    !!user &&
    hasFetchContactsFired &&
    !isFetchingContacts &&
    !fetchingContactsError;
  const isForcePasswordChange = () => forcePasswordChange;

  const signInSso = useCallback(
    async (authCode: string, email: string): Promise<void> => {
      // clear SWR cache
      // SWR still returns old value after clearing cache if dedupingInterval period is not over
      (cache as Map<Key, unknown>).clear();
      try {
        dispatch(loadingSso());
        const {
          data: { requiresPasswordChange, token },
        } = await apiSignInSso(authCode, email);
        setSignedIn(true);
        setActive(true);
        dispatch(actionSignInSso({ requiresPasswordChange, token }));
        updateTokenInStorage(dispatch, token ?? null);
        dispatch(clearLoadingSso());
      } catch (err) {
        setOldPassword("");
        setSignedIn(false);
        updateTokenInStorage(dispatch, null);
        dispatch(clearLoadingSso());
        throw err;
      }
    },
    [cache, dispatch]
  );

  const signInMfa = useCallback(
    async (email: string, password: string, otp: string): Promise<void> => {
      // clear SWR cache
      // SWR still returns old value after clearing cache if dedupingInterval period is not over
      (cache as Map<Key, unknown>).clear();
      try {
        dispatch(loadingMfa());
        const {
          data: { requiresPasswordChange, token },
        } = await apiSignInMfa(email, password, otp);

        if (requiresPasswordChange) {
          // Hold onto old password when forcing password change
          setOldPassword(password);
        }

        setSignedIn(true);
        setActive(true);
        dispatch(actionSignInMfa({ requiresPasswordChange, token }));
        updateTokenInStorage(dispatch, token ?? null);
        dispatch(clearLoadingMfa());
      } catch (err) {
        setOldPassword("");
        setSignedIn(false);
        updateTokenInStorage(dispatch, null);
        dispatch(clearLoadingMfa());
        throw err;
      }
    },
    [cache, dispatch]
  );

  const signInAuthKey = useCallback(
    async (email: string, authKey: string): Promise<void> => {
      // clear SWR cache
      // SWR still returns old value after clearing cache if dedupingInterval period is not over
      (cache as Map<Key, unknown>).clear();
      try {
        const {
          data: { forcePasswordChange, token },
        } = await initiateAuthKeyAuth(email, authKey);

        setSignedIn(true);
        setActive(true);

        // Auth key will always require password change.
        dispatch(
          actionSignInAuthKey({
            requiresPasswordChange: forcePasswordChange,
            token,
          })
        );

        updateTokenInStorage(dispatch, token ?? null);
      } catch (err) {
        setSignedIn(false);
        updateTokenInStorage(dispatch, null);
        dispatch(clearLoadingMfa());
        throw err;
      }
    },
    [cache, dispatch]
  );

  const signOut = useCallback(async () => {
    (cache as Map<Key, unknown>).clear();
    try {
      await apiSignOut();
      setOldPassword("");
      setSignedIn(false);
      setActive(false);
    } finally {
      signOutAndClearToken(dispatch);
    }
  }, [cache, dispatch]);

  const changePassword = useCallback(
    async (newPassword: string) => {
      await apiChangePassword(oldPassword, newPassword, getAuthKey() as string);
      // kick them back to the login screen now instead of letting them
      // continue because their token is now no longer valid.
      dispatch(displayUpdate(t("loginPage.changePassword.success")));
      removeAuthKeyParam();

      await signOut();
    },
    [dispatch, oldPassword, signOut, t]
  );

  const refresh = useCallback(async () => {
    try {
      await apiRefresh();
    } catch (err) {
      signOut();
      throw err;
    }
  }, [signOut]);

  const selectContact = useCallback(
    async (contactId: string, supplierId: string) => {
      const { data: selectContactResponse } = await apiSelectContact(
        contactId,
        supplierId
      );
      updateTokenInStorage(dispatch, selectContactResponse.token ?? null);
    },
    [dispatch]
  );

  const isNeedsRefresh = useCallback(() => {
    return isExpiresSoon(user || null, 2500);
  }, [user]);

  useEffect(() => {
    if (!currentContact?.contactId || !currentContact.thirdPartyId) {
      return;
    }
    selectContact(currentContact.contactId, currentContact.thirdPartyId);
  }, [currentContact, selectContact]);

  // Pre-fetch permissions
  const supplierId = useMemo(() => getSupplierId(user), [user]);
  const shouldFetchPermissions = !!supplierId;
  const { isLoading: isFetchingPermissions } = useFetchPermissions({
    shouldFetch: shouldFetchPermissions,
    suspense: false,
  });

  const timeRemainingInMinutes = useCallback(() => {
    const decoded = decodeToken(user);
    if (decoded == null) {
      return -1;
    }
    // for whatever reason, the tokens are set to exp at a second, not ms
    const currentTime = Math.floor(Date.now() / 1000);
    const tokenExpire = decoded ? decoded.exp : currentTime;
    // have 10 seconds buffer to send sign out api request before token expires
    const secondsBeforeLogOut = tokenExpire - 10;
    const diffInSeconds = secondsBeforeLogOut - currentTime;
    return Math.floor(diffInSeconds / 60);
  }, [user]);

  const userHeartbeatCallback = useCallback(async () => {
    try {
      const decoded = decodeToken(user);
      // if we don't have a user or we're in the middle
      // of a password change, don't check time remaining or refresh
      if (decoded === null || forcePasswordChange) {
        return;
      }
      if (timeRemainingInMinutes() <= 0) {
        signOut();
        dispatch(forcedSignOut());
        return;
      }
      // we only want to refresh if we're close to the expiration time. Let's refresh if we're a minute away from expiration.
      if (isExpiresSoon(user, 5 * 60)) {
        await refresh();
      }
    } catch (err) {
      // don't do anything if we can't parse the token
    }
  }, [
    dispatch,
    forcePasswordChange,
    isActive,
    refresh,
    signOut,
    timeRemainingInMinutes,
    user,
  ]);

  // user heartbeat that runs every 60s
  useInterval(userHeartbeatCallback, 1000 * 60, true);

  const isPending =
    (shouldFetchContacts && isFetchingContacts) ||
    (shouldFetchPermissions && isFetchingPermissions);
  const isSettled =
    hasFetchContactsFired && shouldFetchPermissions && !isPending;

  useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
    }
  }, [isSettled]);

  const loggingSetActive = (b: boolean) => {
    log.debug(`setting active to ${b}`);
    setActive(b);
  };

  if (!firstAttemptFinished && (isPending || isSsoLoading)) {
    return <LoadingDataV2 spinnerSize="giant" />;
  }
  return (
    <AuthContext.Provider
      value={{
        changePassword,
        isAuthenticated,
        isAuthorized,
        isForcePasswordChange,
        isNeedsRefresh,
        refresh,
        signInSso,
        signInMfa,
        signOut,
        isActive,
        setActive: loggingSetActive,
        timeRemaining: timeRemainingInMinutes,
        hasMultipleContacts,
        signInAuthKey,
      }}
    >
      <ForcedLogoutDialog
        showModal={isForciblyLoggedOut}
        setShowModal={() => dispatch(clearForcedSignOut())}
      />
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
