import { isObject } from "../typeGuards";
import { AuthError } from "./AuthError";

/**
 * A typeguard to determine if something is an `AuthError`.
 * @param something any variable to check
 * @returns Whether something is an `AuthError`
 */
export function isAuthError(something: unknown): something is AuthError {
  return (
    isObject(something) && "errorCode" in something && "status" in something
  );
}
