const monitoringActionPrefix = "/monitored-documents/*";
const monitoringActionPrefixWithId = `${monitoringActionPrefix}/id`;
const siteAssessments = "/site-assessments";
const siteAssessmentPrefix = `${siteAssessments}/*`;
const siteAssessmentPrefixWithId = `${siteAssessmentPrefix}/id`;
const frequentlyAskedQuestionsPrefix = "/frequently-asked-questions";
const contactUsPrefix = "/contact-us";

export const ROUTES = Object.freeze({
  home: "/",
  ssoLogin: "/sso-callback",
  monitoringActions: monitoringActionPrefix,
  monitoringActionDetail: `${monitoringActionPrefixWithId}/:marId`,
  siteAssessmentsBase: siteAssessments,
  siteAssessments: siteAssessmentPrefix,
  siteAssessmentDetail: `${siteAssessmentPrefixWithId}/:id`,
  frequentlyAskedQuestions: frequentlyAskedQuestionsPrefix,
  contactUs: contactUsPrefix,
});

// Monitoring Actions route
export type MonitoringActionDetailParamType = {
  marId: string;
};

export const getMonitoringActionDetailRoute = (marId: string) => {
  return `${monitoringActionPrefixWithId}/${marId}`;
};

// Site Assessments route
export type SiteAssessmentDetailParamType = {
  id: string;
};

export const getSiteAssessmentDetailRoute = (id: string) => {
  return `${siteAssessmentPrefixWithId}/${id}`;
};

export type FromAllDueTaskLocationState =
  | { fromAllDueTasks: boolean }
  | undefined;
