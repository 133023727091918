import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";

import { updateToken } from "./actions/auth";
import rootReducer from "./reducers";
import { pullJsonFromLocalStorage } from "./utils/localStorage";
import { tokenKey } from "./constants/auth";

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
});
store.dispatch(updateToken(pullJsonFromLocalStorage<string>(tokenKey)));

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppDispatch = typeof store.dispatch;
export default store;
