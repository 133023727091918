import axios from "axios";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { isAuthError } from "../utils/authError";
import { isProduction } from "../utils/featureFlags";
import { showError, ToastId } from "../utils/toast";

/**
 * Custom hook to dispatch a `showError` toast given an error.
 * Central place to handle the various types of auth errors and
 * display a helpful message.
 * @returns a `showAuthError` function that will dispatch an appropriate `showError` toast given an error.
 */
export function useShowAuthError() {
  const { t } = useTranslation();
  const showAuthError = useCallback(
    (error: unknown) => {
      // Extract the error code from the error.
      let errorCode = "";
      if (axios.isAxiosError(error)) {
        errorCode = error.message; // If it is an AxiosError, use the message property
        const errorResponseData = error.response?.data;
        if (isAuthError(errorResponseData)) {
          errorCode = errorResponseData.errorCode; // If the response data is an `AuthError` object, use the errorCode property
        }
      } else if (typeof error === "string") {
        errorCode = error;
      }

      switch (errorCode) {
        case "auth.sign-in.invalid-credentials":
          showError(
            t("loginPage.errors.invalidEmailPasswordMessage"),
            ToastId.INVALID_CREDENTIALS
          );
          break;
        case "auth.sso.invalid-email":
          showError(
            t("loginPage.errors.emailDoesNotMatch"),
            ToastId.SSO_EMAIL_DOES_NOT_MATCH
          );
          break;
        case "auth.sso.invalid-user":
          showError(
            t("loginPage.errors.userDoesNotExist"),
            ToastId.SSO_USER_DOES_NOT_EXIST
          );
          break;
        case "auth.sso.general-error":
          showError(t("loginPage.errors.ssoError"), ToastId.SSO_ERROR);
          break;
        case "auth.sso.invalid-ad-group":
          isProduction()
            ? showError(
                t("loginPage.errors.notInAdGroupMessageProd"),
                ToastId.NOT_IN_AD_GROUP_PROD
              )
            : showError(
                t("loginPage.errors.notInAdGroupMessageQa"),
                ToastId.NOT_IN_AD_GROUP_QA
              );
          break;
        case "auth.mfa.incorrect-otp":
          showError(t("loginPage.errors.incorrectOtp"), ToastId.INCORRECT_OTP);
          break;
        case "auth.mfa.expired-otp":
          showError(t("loginPage.errors.expiredOtp"), ToastId.EXPIRED_OTP);
          break;
        case "auth.mfa.max-otp-attempt":
          showError(
            t("loginPage.errors.maxOtpAttempts"),
            ToastId.MAX_OTP_ATTEMPTS
          );
          break;
        case "auth.mfa.reset-password-failure":
          showError(
            t("loginPage.errors.resetPasswordError"),
            ToastId.RESET_PASSWORD_ERROR
          );
          break;
        case "service.general.failure":
        default:
          showError(
            t("loginPage.errors.general"),
            ToastId.GENERAL_AUTH_FAILURE
          );
          break;
      }
    },
    [t]
  );
  return { showAuthError };
}
