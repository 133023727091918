import { createReducer } from "@reduxjs/toolkit";

import {
  displayError,
  displayErrorInterpolated,
  displayUpdate,
  displayMessage,
  hideError,
  hideUpdate,
} from "../actions/userMessaging";

export interface UserMessagingState {
  showError: boolean;
  errorGuid: string | null;
  errorInterpolationMap: { [key: string]: string };
  errorText: string | null;
  errorTimestamp: number;
  showUpdate: boolean;
  updateText: string | null;
  userMessage: string | null;
}

const userMessaging = createReducer<UserMessagingState>(
  {
    showError: false,
    errorGuid: null,
    errorInterpolationMap: {},
    errorText: null,
    errorTimestamp: -1,
    showUpdate: false,
    updateText: null,
    userMessage: null,
  },
  {
    [displayError.type]: (state, { payload: errorCode }) => {
      if (typeof errorCode !== "string") {
        return {
          ...state,
          showError: true,
          errorText: null,
          errorTimestamp: Date.now(),
          errorInterpolationMap: {},
        };
      }
      if (errorCode.indexOf(";") >= 0) {
        const [errorText, errorGuid] = errorCode.split(";");
        return {
          ...state,
          showError: true,
          errorGuid,
          errorText,
          errorTimestamp: Date.now(),
          errorInterpolationMap: {},
        };
      }
      return {
        ...state,
        showError: true,
        errorGuid: null,
        errorText: errorCode,
        errorTimestamp: Date.now(),
        errorInterpolationMap: {},
      };
    },
    [displayErrorInterpolated.type]: (
      state,
      { payload: { errorCode, interpolationMap } }
    ) => {
      if (typeof errorCode !== "string") {
        return {
          ...state,
          showError: true,
          errorText: null,
          errorTimestamp: Date.now(),
          errorInterpolationMap: interpolationMap,
        };
      }
      if (errorCode.indexOf(";") >= 0) {
        const [errorText, errorGuid] = errorCode.split(";");
        return {
          ...state,
          showError: true,
          errorGuid,
          errorText,
          errorTimestamp: Date.now(),
          errorInterpolationMap: interpolationMap,
        };
      }
      return {
        ...state,
        showError: true,
        errorGuid: null,
        errorText: errorCode,
        errorTimestamp: Date.now(),
        errorInterpolationMap: interpolationMap,
      };
    },
    [hideError.type]: (state) => ({
      ...state,
      showError: false,
      errorText: null,
      errorInterpolationMap: {},
    }),
    [displayUpdate.type]: (state, action) => {
      if (Date.now() - state.errorTimestamp > 10000) {
        return {
          ...state,
          showError: false,
          errorText: null,
          errorInterpolationMap: {},
          showUpdate: true,
          updateText: action.payload,
        };
      }
      return {
        ...state,
        showUpdate: true,
        updateAutoDismiss: true,
        updateText: action.payload,
      };
    },
    [hideUpdate.type]: (state) => ({
      ...state,
      showUpdate: false,
      updateText: null,
    }),
    [displayMessage.type]: (state, action) => {
      if (Date.now() - state.errorTimestamp > 10000) {
        return {
          ...state,
          showError: false,
          errorText: null,
          errorInterpolationMap: {},
          userMessage: action.payload,
        };
      }
      return {
        ...state,
        userMessage: action.payload,
      };
    },
  }
);

export default userMessaging;
