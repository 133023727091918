import * as log from "loglevel";

export function pullJsonFromLocalStorage<T>(
  key: string,
  initialValue: T | null = null
): T | null {
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(key);
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : initialValue;
  } catch (err) {
    log.error(err);
    return initialValue;
  }
}

export function setInLocalStorage<T>(key: string, value: T | null) {
  try {
    // Save to local storage
    const stringifiedValue = JSON.stringify(value);
    window.localStorage.setItem(key, stringifiedValue);
  } catch (error: unknown) {
    // A more advanced implementation would handle the error case
    log.error(error);
  }
}

export function removeFromLocalStorage(key: string) {
  try {
    // Remove from local storage
    window.localStorage.removeItem(key);
  } catch (error: unknown) {
    // A more advanced implementation would handle the error case
    log.error(error);
  }
}
