declare global {
  interface Window {
    newrelic?: typeof newrelic;
  }
}

export const trackInteraction = (
  eventName: string,
  attributes: Record<string, string | number> = {}
) => {
  if (window && window.newrelic) {
    window.newrelic.addPageAction(eventName, attributes);
  }
};

export const sendCustomAttribute = (name: string, value: string | number) => {
  if (window && window.newrelic) {
    window.newrelic.setCustomAttribute(name, value);
  }
};
