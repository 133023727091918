import store from "../setupStore";

export const fetchWithAuthorization = (
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> => {
  const { token } = store.getState().auth;
  const headersFromInit = init?.headers ?? {};
  const headers = { ...headersFromInit, Authorization: `Bearer ${token}` };
  return fetch(input, { ...init, headers });
};
