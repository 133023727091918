import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { EnhancedStore } from "@reduxjs/toolkit";
import * as log from "loglevel";

import { AppDispatch, RootState } from "../setupStore";
import { signOutAndClearToken, updateTokenInStorage } from "../actions/auth";

function createError<T = unknown>(
  message: string,
  config: InternalAxiosRequestConfig,
  code: string | undefined,
  request: unknown,
  response: AxiosResponse<T>
): AxiosError<T> {
  const error = new AxiosError<T>(message, code, config, request, response);
  error.toJSON = function () {
    return {
      // Standard
      message: this.message,
      name: this.name,
      // Microsoft
      description: undefined,
      number: undefined,
      // Mozilla
      fileName: undefined,
      lineNumber: undefined,
      columnNumber: undefined,
      stack: undefined,
      // Axios
      config: this.config,
      code: this.code,
    };
  };

  return error as AxiosError<T>;
}

export default function setupAxiosInterceptors(
  store: EnhancedStore<RootState>
) {
  const dispatch = store.dispatch as AppDispatch;

  axios.interceptors.request.use(
    (config) => {
      const { token } = store.getState().auth;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    (resp) => {
      if (resp.data.errorCode) {
        if (
          resp.data.status === "UNAUTHORIZED" &&
          resp.data.payload.text !== "Failed to authenticate with TPMC"
        ) {
          log.error("Received UNAUTHORIZED error, signing user out");
          signOutAndClearToken(dispatch);
        }
        const errorGuid: string | undefined = resp.data.payload?.guid;
        // we need to join the error code and error guid together in order to
        // pass both values to the `displayError` invocation further in the app
        // which will split the string if needed
        // we chose to abuse strings rather than update 82 references to
        // `displayError`.
        const joinedErrorCode = errorGuid
          ? `${resp.data.errorCode};${errorGuid}`
          : resp.data.errorCode;
        const error: AxiosError = createError(
          joinedErrorCode,
          resp.config,
          undefined,
          null,
          resp
        );
        return Promise.reject(error);
      }
      if (resp.config.responseType === "blob") {
        return Promise.resolve(resp);
      }
      const respData = resp.data || {};

      console.log("Reponse config:", resp.config, respData);
      console.log("Reponse data:", respData);

      const { payload, token } = respData;
      if (token) {
        updateTokenInStorage(dispatch, token);
      }
      const dataForResp = { ...resp, data: payload };
      return Promise.resolve(dataForResp);
    },
    (error) => {
      if ((error as AxiosError).toJSON != null) {
        log.error((error as AxiosError).toJSON());
      }
      if (error?.response?.status === 401) {
        log.error("Received UNAUTHORIZED error, signing user out");
        signOutAndClearToken(dispatch);
      }
      let errorMessage = JSON.parse(error?.response?.data?.errorCode || "[]");
      errorMessage =
        errorMessage && errorMessage.length > 0
          ? new Error(errorMessage[0]?.message || "")
          : "";
      return Promise.reject(errorMessage || error);
    }
  );
}
