import {
  pullJsonFromLocalStorage,
  setInLocalStorage,
  removeFromLocalStorage,
} from "./localStorage";
import env from "@beam-australia/react-env";

const environment = env("SUPPLIER_PORTAL_ENVIRONMENT");
console.log("ENV:" + environment);
const isMessagingOn = env("MESSAGING_ON");

export function uploadModalOn(): boolean {
  const uploadModalStorageValue: boolean | null =
    pullJsonFromLocalStorage("UPLOAD_MODAL_ON");

  if (uploadModalStorageValue !== null) {
    return uploadModalStorageValue;
  } else {
    return false;
  }
}

export function messagingOn(): boolean {
  return isMessagingOn === "true" || !!pullJsonFromLocalStorage("MESSAGING_ON");
}

export function turnOnMessaging() {
  setInLocalStorage("MESSAGING_ON", true);
}

export function turnOffMessaging() {
  removeFromLocalStorage("MESSAGING_ON");
}

export function isProduction(): boolean {
  return environment === "prod";
}
