import { Action, AnyAction, Reducer } from "redux";

// inspired by reduxsauce's resettableReducer.js
/**
 * Returns a reducer that will revert to default state when a specified action type is passed.
 *
 * @param typesToReset action type causing state revert
 * @param originalReducer reducer to wrap
 * @returns {reducer} resettable reducer
 */
function resettableReducer<S = unknown, A extends Action<string> = AnyAction>(
  typesToReset: string[],
  originalReducer: Reducer<S, A>
): Reducer<S, A> {
  // a valid type is required
  if (!Array.isArray(typesToReset) || !typesToReset.length) {
    throw new Error("A valid reset type array is required");
  }

  // an original reducer is required
  if (typeof originalReducer !== "function") {
    throw new Error("A reducer is required");
  }

  // get the default state
  const resetState = originalReducer(undefined, {} as A);

  function reducer(state = resetState, action: A) {
    if (action && typesToReset.includes(action.type)) {
      return resetState;
    }
    return originalReducer(state, action);
  }

  return reducer;
}

export default resettableReducer;
