import useSWR from "swr";
import { FeaturePermission } from "../../api/GatewayClient";
import { fetcher, GET_PERMISSIONS_URL } from "../../api/supplierPortalApi";

const useFetchPermissions = ({
  shouldFetch,
  suspense,
}: {
  shouldFetch: boolean;
  suspense: boolean;
}) => {
  const { data, error } = useSWR<FeaturePermission[]>(
    shouldFetch ? GET_PERMISSIONS_URL : null,
    fetcher,
    { suspense }
  );

  return {
    permissions: data ?? [],
    isLoading: !error && !data,
    error,
  };
};

export default useFetchPermissions;
