import { useTypedSelector } from "../../setupStore"; // Needs to be imported first
import { AppRoot, Spinner, injectGlobalCss } from "@cof/omni-react";
import classNames from "classnames/bind";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { setEmail } from "../../actions/auth";
import useAuth from "../../hooks/useAuth";
import { useShowAuthError } from "../../hooks/useShowAuthError";
import { getContactId } from "../../utils/jwt";
import {
  getAuthCode,
  getStateParam,
  isSsoLoginRoute,
  removeAuthCodeParam,
  removeSsoLoginRoute,
  removeStateParam,
} from "../../utils/url";
import NewRelicClickListener from "../NewRelicClickListener";
import DismissButton from "../_common/DismissButton";
import styles from "./App.module.scss";

injectGlobalCss();

const loadAuthorizedApp = () => import("./AuthorizedApp");
const loadForceChangePasswordApp = () => import("./ForceChangePasswordApp");
const loadSelectContactApp = () => import("./SelectContactAppV2");
const loadUnauthenticatedApp = () => import("./UnauthenticatedApp");

const AuthorizedApp = React.lazy(loadAuthorizedApp);
const ForceChangePasswordApp = React.lazy(loadForceChangePasswordApp);
const SelectContactApp = React.lazy(loadSelectContactApp);
const UnauthenticatedApp = React.lazy(loadUnauthenticatedApp);

const cx = classNames.bind(styles);

const App = () => {
  const { signInSso } = useAuth();
  const dispatch = useDispatch();
  const { showAuthError } = useShowAuthError();

  // pre-load the authenticated and authorized in the background while the user's
  // filling out the login form.
  React.useEffect(() => {
    loadForceChangePasswordApp();
    loadSelectContactApp();
    loadAuthorizedApp();
  }, []);

  // When there is an authCode in the URL query params,
  // the user is trying to sign in via SSO
  React.useEffect(() => {
    const handleSignInSso = async (authCode: string, email: string) => {
      try {
        await signInSso(authCode, email);
      } catch (error) {
        // In the event of an error, store the email on the state
        // to preserve the user's email in the sign-in form.
        dispatch(setEmail(email));

        showAuthError(error);
      }
    };

    if (isSsoLoginRoute()) {
      const authCode = getAuthCode();
      const stateFromUrl = getStateParam();
      const email = stateFromUrl.email;

      // Only attempt to auth if the authCode query param is in the URL
      if (authCode && typeof email === "string") {
        // Attempt to SSO
        handleSignInSso(authCode, email);
        // Clear auth related query params to clean up URL
        removeStateParam();
        removeAuthCodeParam();
        removeSsoLoginRoute();
      }
    }
  }, [signInSso, dispatch, showAuthError]);

  const currentContact = useTypedSelector(
    (state) => state.currentContact.currentContact
  );
  const forcePasswordChange = useTypedSelector(
    (state) => state.auth.forcePasswordChange
  );
  const user = useTypedSelector((state) => state.auth.token);
  const contactId = getContactId(user);

  const shouldShowForceChangePasswordApp = user && forcePasswordChange;
  const shouldShowSelectContactApp =
    user && (!currentContact || (currentContact && !contactId));
  const shouldShowAuthorizedApp = user && currentContact && contactId;

  let LoadedApp;
  if (shouldShowForceChangePasswordApp) {
    LoadedApp = ForceChangePasswordApp;
  } else if (shouldShowSelectContactApp) {
    // more or less, show select contact app if we have a user and they either:
    // a) don't have a contact selected
    // b) have a contact selected but it hasn't saved yet
    LoadedApp = SelectContactApp;
  } else if (shouldShowAuthorizedApp) {
    LoadedApp = AuthorizedApp;
  } else {
    LoadedApp = UnauthenticatedApp;
  }

  return (
    <React.Suspense
      fallback={
        <div className={cx("suspense-fallback-spinner")}>
          <Spinner theme="dark" size="giant" />
        </div>
      }
    >
      <NewRelicClickListener>
        <DndProvider backend={HTML5Backend}>
          <AppRoot className={cx("app-root")}>
            <ToastContainer
              autoClose={false}
              closeButton={DismissButton}
              closeOnClick={false}
            />
            <LoadedApp />
          </AppRoot>
        </DndProvider>
      </NewRelicClickListener>
    </React.Suspense>
  );
};

export default App;
