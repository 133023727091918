import { combineReducers } from "@reduxjs/toolkit";

import { signOut } from "../actions/auth";

import auth from "./auth";
import currentContact from "./currentContact";
import resettableReducer from "../redux/resettableReducer";
import userMessaging from "./userMessaging";

export default combineReducers({
  auth: resettableReducer([signOut.type], auth),
  currentContact: resettableReducer([signOut.type], currentContact),
  userMessaging: resettableReducer([signOut.type], userMessaging),
});
