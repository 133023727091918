import "react-app-polyfill/stable";
import "whatwg-fetch";
import Root from "./Root";
import { createRoot } from "react-dom/client";
import * as log from "loglevel";

import reportWebVitals from "./reportWebVitals";

import "./i18n";
import "./index.scss";

// Only show WARN and above logs in production
if (process.env.NODE_ENV === "production") {
  log.setDefaultLevel(log.levels.WARN);
} else {
  log.setDefaultLevel(log.levels.DEBUG);
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(log.debug);
