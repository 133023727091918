import React from "react";
import { HashRouter } from "react-router-dom";
import { SWRConfig } from "swr";
import App from "./components/App";
import Providers from "./components/Providers";
import AuthProvider from "./components/Providers/AuthProvider";

const Root = () => (
  <React.StrictMode>
    <HashRouter>
      <Providers>
        <SWRConfig
          value={{
            revalidateOnFocus: false,
            shouldRetryOnError: false,
          }}
        >
          {/*
           * Do not bundle AuthProvider with Providers component, so it doesn't interfere when
           * components are wrapped with Providers for jest test. AuthProvider is for AuthContext (React Context),
           * so it also makes sense to not bundle them with other Providers
           */}
          <AuthProvider>
            <App />
          </AuthProvider>
        </SWRConfig>
      </Providers>
    </HashRouter>
  </React.StrictMode>
);

export default Root;
