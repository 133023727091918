/**
 * Decode HTML entities from an encoded string
 * https://stackoverflow.com/a/7394787/1293256
 * @param html The encoded HTML string
 * @return A decoded HTML string
 */
export function decodeHTML(html: string) {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

/**
 * Replace characters that cause error when calling JSON.parse()
 * @param str
 */
export function prepareStringForJsonParse(str: string) {
  return str
    .replace(/\\\\'/g, "'")
    .replace(/\\\\"/g, '\\"')
    .replace(/\\\\/g, "\\");
}
