import React from "react";
import { Provider as ReduxProvider } from "react-redux";

import store from "../../setupStore";
import setupAxiosInterceptors from "../../utils/apiClient";

type ProvidersProps = {
  children?: React.ReactNode;
};

setupAxiosInterceptors(store);

const Providers = ({ children }: ProvidersProps) => (
  <ReduxProvider store={store}>{children}</ReduxProvider>
);

export default Providers;
