import React from "react";

export interface AuthContextProps {
  changePassword: (newPassword: string) => Promise<void>;

  isAuthenticated: () => boolean;

  isAuthorized: () => boolean;

  isForcePasswordChange: () => boolean;

  isNeedsRefresh: () => boolean;

  refresh: () => Promise<void>;

  signInSso: (authCode: string, email: string) => Promise<void>;

  signInMfa: (email: string, password: string, otp: string) => Promise<void>;

  signInAuthKey: (email: string, authKey: string) => Promise<void>;

  signOut: () => void;

  isActive: boolean;

  setActive: (b: boolean) => void;

  timeRemaining: () => number;

  hasMultipleContacts: boolean;
}

// Provide a default implementation because TypeScript likes them
const AuthContext = React.createContext<AuthContextProps>({
  changePassword: () => Promise.resolve(),
  isAuthenticated: () => false,
  isAuthorized: () => false,
  isForcePasswordChange: () => false,
  isNeedsRefresh: () => false,
  refresh: () => Promise.resolve(),
  signInSso: () => Promise.resolve(),
  signInMfa: () => Promise.resolve(),
  signInAuthKey: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  isActive: false,
  setActive: () => {},
  timeRemaining: () => -1,
  hasMultipleContacts: false,
});

export default AuthContext;
