import { toast } from "react-toastify";
import * as icons from "@cof/omni-icons-react";

export enum ToastId {
  SA_FETCH_FAIL,
  MA_FETCH_FAIL,
  MA_SUBMIT_SUCCESS,
  MA_SUBMIT_FAIL,
  MANUAL_SUBMISSION_FAIL,
  MANUAL_SUBMISSION_SUCCESS,
  EVIDENCE_UPLOAD_FAIL,
  EVIDENCE_UPLOAD_SUCCESS,
  EVIDENCE_DELETE_FAIL,
  EVIDENCE_DELETE_SUCCESS,
  PORTAL_ACCESS_FAIL,
  PORTAL_ACCESS_SUCCESS,
  FILE_NAME_TOO_LONG,
  FILE_TOO_LARGE,
  DUPLICATE_FILE_NAME,
  UNSUPPORTED_EXTENSION,
  CONTACT_DELETE_SUCCESS,
  CONTACT_DELETE_FAIL,
  PRIMARY_SWAP_SUCCESS,
  PRIMARY_SWAP_FAIL,
  EDIT_CONTACT_SUCCESS,
  EDIT_CONTACT_FAIL,
  CREATE_CONTACT_FAIL,
  CREATE_CONTACT_SUCCESS,
  EVIDENCE_DOWNLOAD_FAIL,
  SUBCONTRACTOR_SEARCH_RESULTS_FAIL,
  INVALID_HQ_CODE,
  FOURTH_PARTY_CREATION_FAIL,
  MISSING_CLOUD_DEPENDENCY,
  INVALID_FOURTH_PARTY,
  CREATE_COURTSHIP_SUCCESS,
  CREATE_COURTSHIP_FAIL,
  CREATE_SUBCONTRACTOR_FAIL,
  REM_PLAN_UPLOAD_SUCCESS,
  REM_PLAN_UPLOAD_FAIL,
  MISSING_CLOUD_VALUE,
  INVALID_FOURTH_PARTY_ID,
  INVALID_COURTSHIP_ID,
  SUBCONTRACTOR_UPDATE_FAIL,
  SUBCONTRACTOR_UPDATE_SUCCESS,
  MATERIAL_RATIONALE_FETCH_FAIL,
  CLOUD_PLATFORM_FETCH_FAIL,
  COURTSHIP_LOCATION_FETCH_FAIL,
  INVALID_MATERIAL_SUB_ID,
  DEACTIVATE_COURTSHIP_SUCCESS,
  DEACTIVATE_COURTSHIP_FAIL,
  RESET_PASSWORD_EMAIL_FAIL,
  INVALID_CAP_ONE_USER,
  SSO_EMAIL_DOES_NOT_MATCH,
  SSO_USER_DOES_NOT_EXIST,
  SSO_ERROR,
  NOT_IN_AD_GROUP_PROD,
  NOT_IN_AD_GROUP_QA,
  INCORRECT_OTP,
  EXPIRED_OTP,
  MAX_OTP_ATTEMPTS,
  RESEND_OTP,
  RESET_PASSWORD_ERROR,
  GENERAL_AUTH_FAILURE,
  INVALID_CREDENTIALS,
}

export const showSuccess = (message: string, toastId: ToastId) => {
  toast.success(message, {
    icon: icons.Checkmark,
    autoClose: 5000,
    closeButton: false,
    toastId: toastId,
  });
};

export const showError = (message: string, toastId: ToastId) => {
  toast.error(message, {
    icon: icons.Caution,
    toastId: toastId,
  });
};

export const dismissAll = () => {
  toast.dismiss();
};
