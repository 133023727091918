import { useContext } from "react";

import AuthContext from "../context/auth";

/**
 * React Hook that acts as `AuthContext.Consumer`.
 * @returns {{}}
 */
export default function useAuth() {
  return useContext(AuthContext);
}
