import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { decodeHTML, prepareStringForJsonParse } from "../utils/decodeHTML";

import {
  ContactSearchResult,
  ContactTypeCollectionDto,
  ContactView,
  Courtship,
  CourtshipCreateRequest,
  CourtshipUpdateRequest,
  DocumentKeyAndStatus,
  FinalizeMitigationSubmission,
  FourthParty,
  FourthPartyCreateRequest,
  FourthPartySearchRequest,
  IatpmRemediationPlanDto,
  ManualSubmissionRequest,
  RefreshResponse,
  ResetPasswordResponse,
  SelectContactResponse,
  SignInResponse,
  SupplierLocationAddress,
  SupplierLocationCreateRequest,
} from "./GatewayClient";
import { fetchWithAuthorization } from "./fetchWithAuthorization";

axios.interceptors.response.use((response) => {
  try {
    return JSON.parse(
      prepareStringForJsonParse(
        prepareStringForJsonParse(decodeHTML(JSON.stringify(response)))
      )
    );
  } catch (e) {
    return response;
  }
});

export const fetcher = (url: string) => axios.get(url).then((res) => res.data);

export const fetcherWithParams = (
  url: string,
  params: AxiosRequestConfig["params"]
) => {
  return axios
    .get(url, {
      params,
    })
    .then((res) => res.data);
};

//* ***************************************************************************************
// Supplier Portal - Authentication API
//* ***************************************************************************************

export const signInSso = async (
  authCode: string,
  username: string
): Promise<AxiosResponse<SignInResponse>> => {
  return axios.post("/api/public/signin/sso", {
    authorizationCode: authCode,
    username,
  });
};

// A preliminary check for if the user exists before redirecting to SSO login
export const initiateSso = async (username: string): Promise<AxiosResponse> => {
  return axios.post("/api/public/signin/initiate-sso", {
    username,
  });
};

// Initiates MFA login process by performing username/password authentication and sending OTP email
export const initiateMfa = async (
  username: string,
  password: string
): Promise<AxiosResponse> => {
  return axios.post("/api/public/signin/mfa", {
    username,
    password,
  });
};

export const initiateAuthKeyAuth = async (
  username: string,
  authKey: string
): Promise<AxiosResponse<{ forcePasswordChange: boolean; token: string }>> => {
  return axios.post("/api/public/signin/authkey", {
    username,
    authKey,
  });
};

// Completes MFA login process by validating OTP
export const signInMfa = async (
  username: string,
  password: string,
  mfaOtp: string
): Promise<AxiosResponse<SignInResponse>> => {
  return axios.post("/api/public/signin/validate-mfa-otp", {
    username,
    password,
    mfaOtp,
  });
};

export const mfaResetPassword = async (
  username: string
): Promise<AxiosResponse<ResetPasswordResponse>> => {
  return axios.post("/api/public/mfa-reset-password", { username });
};

export const refresh = async (): Promise<AxiosResponse<RefreshResponse>> => {
  return axios.get("/api/refresh");
};

export const changePassword = async (
  oldPassword: string,
  newPassword: string,
  authKey?: string
): Promise<AxiosResponse<void>> => {
  return axios.post("/api/protected/change-password", {
    oldPassword,
    newPassword,
    authKey,
  });
};

export const signOut = async (): Promise<AxiosResponse<void>> => {
  return axios.post("/api/protected/signout");
};

//* ***************************************************************************************
// Supplier Portal - Contacts API
//* ***************************************************************************************

export const selectContact = async (
  contactId: string,
  supplierId: string
): Promise<AxiosResponse<SelectContactResponse>> => {
  return axios.put("/api/contacts/select-contact", {
    contactId,
    supplierId,
  });
};

export const createNewContact = async (
  body: ContactView
): Promise<AxiosResponse<ContactView>> => {
  return axios.post(`/api/contacts`, body);
};

export const deleteContact = async (
  targetContactId: string
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/contacts/deactivate`, {
    params: { contactId: `${targetContactId}` },
  });
};

export const editExistingContact = async (
  body: ContactView
): Promise<AxiosResponse<ContactView>> => {
  return axios.put(`/api/contacts`, body);
};

export const GET_ALL_CONTACTS_URL = "/api/contacts/all";

export const GET_AVAILABLE_CONTACTS_URL = "/api/contacts/current";
export const getAvailableContacts = async (): Promise<
  AxiosResponse<ContactView[]>
> => {
  return axios.get(GET_AVAILABLE_CONTACTS_URL);
};

export const GET_CONTACT_TYPES_URL = "/api/contacts/types";

export const searchContactsByEmail = async (
  email: string
): Promise<AxiosResponse<ContactSearchResult[]>> => {
  return axios.get(`/api/contacts?email=${email}`);
};

export const swapPrimaryContact = async (
  body: ContactView
): Promise<AxiosResponse<void>> => {
  return axios.patch(`/api/contacts/promote`, body);
};

export const GET_HAS_REQUIRED_CONTACT_TYPES_URL =
  "/api/contacts/validate-supplier-contact-types";

export const listRequiredContactTypes = async (): Promise<
  AxiosResponse<ContactTypeCollectionDto>
> => {
  return axios.get(`/api/contacts/list-required-contact-types`);
};

//* ***************************************************************************************
// Supplier Portal - Monitoring Actions API
//* ***************************************************************************************

export const GET_MONITORING_ACTIONS_URL =
  "/api/monitoring-actions/all?dayRange=365";

export const GET_DUE_MONITORING_ACTIONS_URL =
  "/api/monitoring-actions/due?dayRange=365";

export const declineComments = async (
  marId: string,
  comment: ManualSubmissionRequest
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/monitoring-actions/decline?marId=${marId}`, comment);
};

export const GET_MONITORING_ACTION_EVIDENCE_URL =
  "/api/monitoring-actions/evidence-list";
export const getMonitoringActionEvidenceUrl = (marId: string) =>
  `${GET_MONITORING_ACTION_EVIDENCE_URL}?marId=${marId}`;

export const monitoringActionsDocumentDownload = async (
  marId: string,
  originalName: string
) => {
  return fetchWithAuthorization(
    `/api/monitoring-actions/evidence?marId=${marId}&originalName=${originalName}`
  );
};

export const monitoringActionsDocumentDelete = async (
  marId: string,
  originalName: string
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/monitoring-actions/evidence`, {
    params: {
      originalName,
      marId,
    },
  });
};

export const getUploadMonitoringActionsEvidenceUrl = (marId: string) =>
  `/api/monitoring-actions/upload-evidence?marId=${marId}`;

export const uploadMonitoringActionEvidence = async (
  marId: string,
  file: File,
  convertToPdf: boolean = false
): Promise<AxiosResponse<DocumentKeyAndStatus[]>> => {
  const formData = new FormData();

  formData.append("files", file as Blob);

  return axios.post(getUploadMonitoringActionsEvidenceUrl(marId), formData, {
    params: {
      convertToPdf,
    },
  });
};

export const monitoringActionsUpload = async (
  marId: string,
  files: File[],
  convertToPdf: boolean = false,
  dateFileNameModifier: string
): Promise<AxiosResponse<DocumentKeyAndStatus[]>> => {
  const formData = new FormData();
  files.forEach((file) => {
    const fileWithTimestampName = new File(
      [file],
      file.name.replace(/(\.[\w\d_-]+)$/i, `-${dateFileNameModifier}$1`),
      {
        type: file.type,
        lastModified: file.lastModified,
      }
    );

    formData.append("files", fileWithTimestampName as Blob);
  });

  return axios.post(getUploadMonitoringActionsEvidenceUrl(marId), formData, {
    params: {
      convertToPdf,
    },
  });
};

export const monitoringActionsSubmit = async (
  marId: string
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/monitoring-actions/submit?marId=${marId}`);
};

//* ***************************************************************************************
// Supplier Portal - Subcontractors API
//* ***************************************************************************************

export const saveSubcontractorLocation = async (
  body: SupplierLocationCreateRequest
): Promise<AxiosResponse<SupplierLocationAddress>> => {
  return axios.post(`/api/subcontractors/locations`, { ...body });
};

export const GET_SUBCONTRACTOR_COURTSHIPS_URL =
  "/api/subcontractors/courtships";

export const GET_COURTSHIP_STATUSES_URL =
  "/api/subcontractors/courtships/statuses";

export const getCourtshipLocations = async (
  courtshipId: number
): Promise<AxiosResponse<SupplierLocationAddress[]>> => {
  return axios.get(
    `/api/subcontractors/courtships/locations?courtshipId=${courtshipId}`
  );
};

export const GET_SUPPLIER_LOCATIONS_URL = "/api/subcontractors/locations";

export const GET_MATERIAL_RATIONALES_URL =
  "/api/subcontractors/material-enablements";

export const submitCourtship = async (
  body: CourtshipCreateRequest
): Promise<AxiosResponse<Courtship>> => {
  return axios.post("/api/subcontractors/courtships", { ...body });
};

export const updateCourtship = async (
  body: CourtshipUpdateRequest
): Promise<AxiosResponse<Courtship>> => {
  return axios.put("/api/subcontractors/courtships", { ...body });
};

export const deactivateCourtship = async (courtshipId: number) => {
  return axios.delete(`/api/subcontractors/courtships`, {
    params: { courtshipId },
  });
};

export const createFourthParty = async (
  body: FourthPartyCreateRequest
): Promise<AxiosResponse<FourthParty>> => {
  return axios.post("/api/subcontractors", { ...body });
};

export const searchFourthParties = async (
  body: FourthPartySearchRequest
): Promise<AxiosResponse<FourthParty[]>> => {
  return axios.post("/api/subcontractors/search", { ...body });
};

export const GET_CLOUD_PLATFORMS_URL = "/api/subcontractors/cloud-platforms";

//* ***************************************************************************************
// Supplier Portal - Locations API
//* ***************************************************************************************

/**
 * Get the Countries defined in the Third Party Data Store application (Pack-mule) through
 * the Supplier Portal
 */
export const GET_COUNTRIES_URL = "/api/locations/countries";

export const GET_COUNTRY_REGIONS_URL = "/api/locations/countries/regions";

//* ***************************************************************************************
// Supplier Portal - Permissions API
//* ***************************************************************************************

export const GET_PERMISSIONS_URL = "/api/permissions";

//* ***************************************************************************************
// Supplier Portal - Site Assessments (IATPM Findings) API
//* ***************************************************************************************
export const GET_SITE_ASSESSMENTS_URL = "/api/iatpm-findings";

export const SUBMIT_IATPM_REMEDIATION_PLAN_URL =
  "/api/iatpm-findings/remediation-plan";

export const submitIatpmRemediationPlan = async (
  body: IatpmRemediationPlanDto
) => {
  return axios.put(SUBMIT_IATPM_REMEDIATION_PLAN_URL, body);
};

export const GET_IATPM_REMEDIATION_EVIDENCE_URL =
  "/api/iatpm-findings/remediation-evidence-list";
export const getIatpmRemediationEvidenceUrl = (iatpmId: string) =>
  `${GET_IATPM_REMEDIATION_EVIDENCE_URL}?iatpmId=${iatpmId}`;

export const getUploadIatpmEvidenceUrl = (iatpmId: string) =>
  `/api/iatpm-findings/remediation-evidence?iatpmId=${iatpmId}`;

export const iatpmRemediationEvidenceUpload = async (
  iatpmId: string,
  document: File[]
): Promise<AxiosResponse<DocumentKeyAndStatus[]>> => {
  const formData = new FormData();
  document.forEach((file) => {
    formData.append("files", file as Blob);
  });
  return axios.put(getUploadIatpmEvidenceUrl(iatpmId), formData);
};

export const uploadIatpmRemediationEvidence = async (
  iatpmId: string,
  file: File
) => {
  const formData = new FormData();
  formData.append("files", file as Blob);

  return axios.put(getUploadIatpmEvidenceUrl(iatpmId), formData);
};

export const deleteIatpmEvidenceUrl = (iatpmId: string, originalName: string) =>
  `/api/iatpm-findings/remediation-evidence?iatpmId=${iatpmId}&originalName=${originalName}`;

export const iatpmRemediationEvidenceDelete = async (
  iatpmId: string,
  originalName: string
): Promise<AxiosResponse<void>> => {
  return axios.delete(deleteIatpmEvidenceUrl(iatpmId, originalName));
};

export const iatpmDocumentDownload = async (
  iatpmId: string,
  originalName: string
) => {
  return fetchWithAuthorization(
    `/api/iatpm-findings/remediation-evidence?iatpmId=${iatpmId}&originalName=${originalName}`
  );
};

export const submitIatpmRemediationEvidence = async (
  iatpmFindingId: string,
  supplierComments?: string
): Promise<AxiosResponse<void>> => {
  const iatpmSubmissionBody: FinalizeMitigationSubmission = {
    iatpmFindingId,
    supplierComments,
  };
  return axios.post("/api/iatpm-findings/submit", iatpmSubmissionBody);
};

//* ***************************************************************************************
// Supplier Portal - Tooltip API
//* ***************************************************************************************
export const GET_TOOLTIPS_URL = "/api/tooltips";

//* ***************************************************************************************
// Supplier Portal - Supplier Insurance Management API
//* ***************************************************************************************
export const GET_CONTRACTS_WITH_INSURANCE_REQUIREMENTS_URL =
  "/api/supplier-insurance-management/cirs";

export const COI_URL_BASE = "/api/supplier-insurance-management/cois";

export const LIST_ALL_CERTIFICATES = `${COI_URL_BASE}/list-all`;

export const coiUpload = async (
  document: File[]
): Promise<AxiosResponse<DocumentKeyAndStatus[]>> => {
  const formData = new FormData();
  document.forEach((file) => {
    formData.append("files", file as Blob);
  });
  return axios.post(COI_URL_BASE, formData);
};

export const coiSubmit = async (): Promise<AxiosResponse<void>> => {
  return axios.put(
    `/api/supplier-insurance-management/cirs/finalize-submission`
  );
};

export const coiDocumentDelete = async (
  originalName: string
): Promise<AxiosResponse<void>> => {
  return axios.delete(COI_URL_BASE, {
    params: {
      originalName,
    },
  });
};

export const certificatesDocumentDownload = async (originalName: string) => {
  return fetchWithAuthorization(`${COI_URL_BASE}?originalName=${originalName}`);
};
