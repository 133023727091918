import { createAction } from "@reduxjs/toolkit";
import { ContactView } from "../api/GatewayClient";

export const selectCurrentContact = createAction<ContactView>(
  "currentContact/select"
);

export const demoteCurrentPrimary = createAction<ContactView>(
  "currentContact/demote"
);

export const clearCurrentContact = createAction("currentContact/clear");
