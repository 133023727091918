import { createAction } from "@reduxjs/toolkit";

export type ErrorInterpolation = {
  errorCode: string;
  interpolationMap: { [key: string]: string };
};

// user error errorCode
export const displayError = createAction<string>("userMessaging/displayError");

// user error errorCode with extra message placeholders
export const displayErrorInterpolated = createAction<ErrorInterpolation>(
  "userMessaging/displayErrorInterpolated"
);

export const hideError = createAction("userMessaging/hideError");

// user update (typically success)
export const displayUpdate = createAction<string>(
  "userMessaging/displayUpdate"
);

export const hideUpdate = createAction("userMessaging/hideUpdate");

// default user errorCode to display
export const displayMessage = createAction<string>(
  "useMessaging/displayMessage"
);
