import { ReactNode, useCallback, useEffect } from "react";
import { spUserClick } from "../../constants/newrelic";
import { trackInteraction } from "../../utils/newrelic";

interface NewRelicClickListenerProps {
  children?: ReactNode;
}

const getNewRelicId = (element: HTMLElement) => {
  let parentElement = element;
  let newRelicAttribute = parentElement.getAttribute("data-newrelic-id");
  if (newRelicAttribute) {
    return newRelicAttribute;
  }

  while (parentElement.parentElement !== null) {
    parentElement = parentElement.parentElement;
    newRelicAttribute = parentElement.getAttribute("data-newrelic-id");
    if (newRelicAttribute) {
      return newRelicAttribute;
    }
  }
  return null;
};

const NewRelicClickListener = ({ children }: NewRelicClickListenerProps) => {
  const handleClick = useCallback((event: MouseEvent) => {
    const { target } = event;
    if (target && target instanceof HTMLElement) {
      const newRelicAttribute = getNewRelicId(target);

      if (newRelicAttribute) {
        trackInteraction(spUserClick, { elementClicked: newRelicAttribute });
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, [handleClick]);
  return <>{children}</>;
};

export default NewRelicClickListener;
