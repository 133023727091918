import { createReducer } from "@reduxjs/toolkit";

import {
  selectCurrentContact,
  demoteCurrentPrimary,
  clearCurrentContact,
} from "../actions/currentContact";
import { ContactView } from "../api/GatewayClient";

export interface CurrentContactState {
  currentContact: ContactView | null;
  isSwitchingSuppliers: boolean;
}

const currentContact = createReducer<CurrentContactState>(
  {
    currentContact: null,
    isSwitchingSuppliers: false,
  },
  {
    [selectCurrentContact.type]: (state, action) => ({
      ...state,
      currentContact: action.payload,
      isSwitchingSuppliers: false,
    }),
    [demoteCurrentPrimary.type]: (state, action) => ({
      ...state,
      currentContact: action.payload,
    }),
    [clearCurrentContact.type]: (state) => ({
      ...state,
      currentContact: null,
      isSwitchingSuppliers: !state.isSwitchingSuppliers,
    }),
  }
);

export default currentContact;
