import { pullJsonFromLocalStorage } from "./localStorage";
import { tokenKey } from "../constants/auth";
import { ROUTES } from "./routes";

/**
 * Get the AuthCode from "code" url query param if it exists and the user is not already authenticated.
 * @returns The auth code or null (if there is no query param) or undefined (if the user is already authenticated).
 */
export function getAuthCode(): string | null | undefined {
  const storedAccessToken = pullJsonFromLocalStorage<string>(tokenKey);
  if (storedAccessToken) {
    // Early return when an access token already exists in local storage,
    // indicating the user is already authenticated.
    return;
  }
  return getQueryParam("code");
}

/** Remove the "code" query param from the URL. */
export function removeAuthCodeParam() {
  removeQueryParam("code");
}

/**
 * Get the auth key from "authKey" url query param if it exists.
 * @returns The auth key or null (if there is no query param).
 */
export function getAuthKey(): string | null | undefined {
  return getQueryParam("authKey");
}

/** Remove the "authKey" query param from the URL. */
export function removeAuthKeyParam() {
  removeQueryParam("authKey");
}

/**
 * Extract the URL search param "state" and return the JSON value.
 * @returns The JSON object stored in the "state" query parameter if it exists.
 */
export function getStateParam(): Record<string, unknown> {
  const stateParam = getQueryParam("state");
  try {
    return stateParam ? JSON.parse(decodeURIComponent(stateParam)) : {};
  } catch (e) {
    console.warn(`Failed to parse the state param: ${stateParam}`, e);
    return {};
  }
}

/** Remove the "state" query param from the URL. */
export function removeStateParam() {
  removeQueryParam("state");
}

/**
 * Get the value of a query parameter from the URL.
 * @param param The name of the query parameter for which to get the value.
 * @returns The value of the given query parameter if it exists, otherwise null;
 */
export function getQueryParam(param: string): string | null {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}

/**
 * Remove a query paramater and value from the URL.
 * @param param the parameter name to remove.
 */
export function removeQueryParam(param: string) {
  const newUrl = new URL(window.location.href);
  newUrl.searchParams.delete(param);
  window.history.pushState(null, "", newUrl.toString());
}

/**
 * Check if user is on SSO login url.
 * @returns The boolean value of whether the user is on the SSO login url.
 */
export function isSsoLoginRoute(): boolean {
  return window.location.pathname === ROUTES.ssoLogin;
}

/** Removes SSO login route from url. */
export function removeSsoLoginRoute(): void {
  const newUrl = new URL(window.location.origin);
  window.history.pushState(null, "", newUrl.toString());
}
