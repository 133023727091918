import { useEffect, useRef } from "react";

export default function useInterval(
  callback: () => void,
  delay: number,
  tickOnStart: boolean = false
) {
  const savedCallback = useRef(() => {});

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      if (tickOnStart) {
        tick();
      }
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, tickOnStart]);
}
